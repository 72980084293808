import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import ReactGA from 'react-ga4';
import "./index.css";

// Initialize Google Analytics with your measurement ID
ReactGA.initialize('G-L3VNNM50HZ'); // Replace with your GA4 measurement ID

// Send the initial pageview
ReactGA.send('pageview');

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// Register service worker and handle updates
serviceWorkerRegistration.register({
	onUpdate: (registration: any) => {
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener("statechange", (event: any) => {
				if (event.target.state === "activated") {
					// Notify user about the update
					const userConsent = window.confirm("A new version is available. Would you like to reload?");
					if (userConsent) {
						window.location.reload();
					}
				}
			});
			waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
		}
	},
});

// Log web vitals for performance monitoring
reportWebVitals();
